import React, { useState, useContext, useEffect } from 'react';
import {Breadcrumb, Button, ButtonGroup } from 'react-bootstrap';
import MarkSheetDisplay from './MarkSheetDisplay';
import Preloader from '../../components/Preloader/Preloader';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome, faTable, faCertificate } from '@fortawesome/free-solid-svg-icons';
//import { FaHome, FaRegBookmark } from "react-icons/fa";
import { ProductContext } from '../../ProductProvider';
import { Link } from "react-router-dom";
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
//import { CSVLink } from "react-csv";
//import ReactToPrint from "react-to-print";
//import { downloadMarkSheetHeaders } from '../../ProData';
//import { PiNutFill } from 'react-icons/pi';
import MarkSheetView from '../student/MarkSheetView';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function MarksMainView() {
    const { loggedUser, validateLogin } = useContext(ProductContext);
    const [viewSelected, setViewSelected] = useState("none");
    const [searchResults, setSearchResults] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const componentRef = React.useRef(null);
    const csvLinkEl = React.createRef();
  
    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          //processBatchYear();
            handleSearch();
            //console.log("Logged Use : ", loggedUser);
            setViewSelected("Print");
        }else{
            validateLogin();
        }
        //setLoaded(true);
       // console.log(batchYear);
      },[loggedUser])
    
      const handleSearch=async()=>{
        //event.preventDefault();
        try{
            setLoaded(false);
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const searchOption="AppID";
            const searchValue=loggedUser.nickname;
            //const searchValue="23BTH1001";
            //console.log("searchvlaue : ", searchValue);
          //await axios.get(`${config.api.invokeURL}/dashboard/student/marksheet?searchValue=${searchValue}&searchOption=${searchOption}`, {
            //await axios.get(`${config.api.invokeURL}/dashboard/student/marksheet/view?searchValue=${searchValue}&searchOption=${searchOption}`, {
            await axios.get(`${config.api.invokeURL}/dashboard/student/marksheet/view?searchValue=${searchValue}`, {
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data);
            if(response.data.length>0)
            {
                setSearchResults(response.data);
            }
            enqueueSnackbar("Marksheet loaded successfully",{variant:'success'}); 
            setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
      })
        }catch(error){
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
        }
      }

  /*const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);*/

 const handleViewChange=(event, mode)=>{
    event.preventDefault();
    setViewSelected(mode);

 }
    
  return (
    <div>
      <Helmet>
      <title>Marksheet - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Marksheet - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Marksheet - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/marksheet" />
    </Helmet>
   
    <Preloader show={loaded ? false : true} />
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Students
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Mark Sheet
    </Breadcrumb.Item>
    </Breadcrumb>
   {/*} <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">  
    <h4>Marksheet Details</h4>
    </div>
   {/*} <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="marksheet"
      trigger={reactToPrintTrigger}
    />
        <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadMarkSheetHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
          />
        </ButtonGroup>

      </div>
    </div>*/}
     <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
      <ButtonGroup>
        <Button onClick={event=>handleViewChange(event, "Print")}> <i className="fa fa-bookmark" aria-hidden="true"></i></Button>
        <Button onClick={event=>handleViewChange(event, "Table")}> <i className="fa fa-table" aria-hidden="true"></i></Button>
        </ButtonGroup>
        </div>
        </div>

    {loaded && searchResults.length>0 && viewSelected==="Print"?
    <MarkSheetDisplay searchResults={searchResults}/>
    :loaded && searchResults.length>0 && viewSelected==="Table"?
    <MarkSheetView searchResults={searchResults}/>:<h3>No Data to Display</h3>}
    
    </div>
  )
}

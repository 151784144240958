import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';

const ProductContext = React.createContext();
const config = require('./config.json');


const ProductProvider = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loggedUser, setLoggedUser] = useState({
        name: 'Guest', email: '', website:'', profile:'Guest', DOB:'', isAuthenticated:false, mobile:'', nickname:'',gender:'',family_name:'',phone_number:'',});
    const [myProfileImage, setMyProfileImage] = useState('defaultAvatar.jpg');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [newMemberimage, setNewMemberImage] = useState('defaultAvatar.jpg');
    const [sidebarStatus, setSidebarStatus]=useState('Full');

    const navigate = useNavigate();

    const validateLogin = async() =>{
        try{
            await fetchAuthSession()
            .then(async(user)=>{
            //console.log("User : ", user);
            var tadmin=false;
            const {email, profile, name, nickname,family_name, birthdate, phone_number, gender, website, picture }= user.tokens.idToken.payload;
            if(profile==="Admin"||profile==="Student"||profile==="Super Student"||profile==="Super Admin"){
                tadmin=true;
                await setLoggedUser({...loggedUser,
                name:name,
                email:email,
                profile:profile,
                DOB:birthdate,
                website:website,
                nickname:nickname,
                //nickname:"23BTH1001",
                gender:gender,
                family_name:family_name,
                phone_number:phone_number,
                isAuthenticated:true,
                picture:picture,
                isAdmin:tadmin
            });
            await setSidebarStatus("Shrunk");
            if(profile==="Admin"||profile==="Super Admin"){
                const tImageName=nickname+".jpeg";
               // const tImageName="23BTH1001"+".jpeg";

                setMyProfileImage(`${config.s3.imageURL}${"avatars/admins/"}${tImageName}`);
            }else if(profile==="Student"||profile==="Super Student"){
                const tImageName=picture+".jpeg";
           //     const tImageName=picture+".jpeg";
           var timestamp = new Date().getTime();      
                setMyProfileImage(`${config.s3.ProfileImageURL}${"avatar/"}${picture}${"/"}${tImageName}${"?t="}${timestamp}`);
                //console.log(`${config.s3.ProfileImageURL}${"avatar/"}${picture}${"/"}${tImageName}${"?t="}${timestamp}`);
            }
            navigate("/");
            //console.log("User : ", loggedUser);
            // fetchOpenItems();
            }else{
                enqueueSnackbar("You are not authorized to access this portal",{variant : 'warning'});
                navigate("/login");
            }        
            })
            .catch((error) =>{
            //console.log("Error Occured - " + error.message);
            setLoggedUser({...loggedUser,
                name:'',
                email:'',
                DOB:'',
                profile:'',
                website:'',
                nickname:'',
                gender:'',
                family_name:'',
                phone_number:'',
                picture:'',
                isAuthenticated:false,
                isAdmin:false
            }); 
            navigate("/login");
        })     
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            navigate("/login");
        }
    }

    const generateDate=()=>{
        const current_datetime = new Date();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        const accountNo = tempUserId.concat(date_year,"-",month_value,"-", date_value);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;
        //console.log("acc : ", accountNo);
      
    }

    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            isCollapsed,
            setIsCollapsed,
            validateLogin,
            generateDate,
            myProfileImage,
            setMyProfileImage,
            newMemberimage, 
            setNewMemberImage,
            sidebarStatus, 
            setSidebarStatus

        }}>
        {props.children}
        </ProductContext.Provider>
    )
}

export {ProductProvider, ProductContext};

import React, { useEffect, useContext, useState } from 'react';
import { Form, Col, Row, Breadcrumb, Card, Button, ButtonGroup } from 'react-bootstrap';
import axios from "axios";
import { ProductContext } from '../../ProductProvider';
import Preloader from '../../components/Preloader/Preloader';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
import { phoneCode } from '../../ProData';
import { useSnackbar } from 'notistack';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
import ProfileImage from '../../components/profile/ProfileImages';
//import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
//import {FaHome} from 'react-icons/fa';
import {Helmet} from 'react-helmet-async';
import DocPhotoProof from './DocPhotoProof';

const config = require('../../config.json');

export default function MyProfile() {
  const { loggedUser, validateLogin, myProfileImage, setMyProfileImage } = useContext(ProductContext);
  const [profileDetails, setProfileDetails]=useState({applicantName:'', fatherName:'', dateOfBirth:'', gender:'Male', nationality:'',
  religiousStatus:'Scholastic', parishDiocese:'', congregationProvince:'', areaCode:'+91',mobileNumber:'', emailID:'', placeOfBirth:'', 
  birthDistrict:'', birthState:'', localAddress:'', localState:'', localPincode:'', localContact:'', perAddress:'', perState:'', perPincode:'',
  perContactNo:'', courseCategory:'Bachealor of Theology', degreeDetails:[], languageDetails:[], courseDetails:[]});
  const [loaded, setLoaded] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  const [profileImage, setProfileImage] = useState(logo);
  const { enqueueSnackbar } = useSnackbar();
  //const [newProfileImage, setNewProfileImage]=useState(logo)
  const [imageFile, setImageFile]=useState('');
  const [formType, setFormType]= useState('Edit');
  const componentRef = React.useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      fetchMyProfile();
    }else{
      validateLogin();
    }
  },[loggedUser])

  const fetchMyProfile=async()=>{
    //event.preventDefault();
    try{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const searchTopic="RegistrationID";
        const searchValue=loggedUser.nickname;
//        const searchValue="23BTH1001";

      await axios.get(`${config.api.invokeURL}/dashboard/student/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
       console.log("response : ", response.data);
       await setProfileImage(myProfileImage);

        if(response.data.length>0)
        {
            setProfileDetails(response.data[0]);
            await setProfileImage(myProfileImage);
        }
        enqueueSnackbar("Profile loaded successfully!",{variant:'success'}); 
        setLoaded(true);
    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
  })
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    }
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="lg">Print</Button>;
  }, []);

  const postOnS3 = async(event,inputType)=>{
    event.preventDefault();
    const applicationno=loggedUser.picture;
    try{
        //  console.log("NewImage : ", newImage);
        if(imageFile===""){
          enqueueSnackbar("No Photo Attached!",{variant:'info'}); 
      }else{
        var tKeyName="";
        var newFileName="";
        if(inputType==="Profile"){
          tKeyName="/avatar/"+applicationno;   
          newFileName=applicationno+".jpeg";
        }else if (inputType==="Recomm"){
          tKeyName="/avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_recommLetter.jpeg"; 
        }else if (inputType==="CERTProof"){
          tKeyName="/avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_certproof.jpeg"; 
        }else if (inputType==="MarkList"){
          tKeyName="/avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_markproof.jpeg"; 
        }   
      
      //console.log("newfilename :", newFileName);
     // const newImage="";
      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      //const session = await Auth.currentSession();
      //const access_token=session.idToken.jwtToken;
     // const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const bucketName=config.s3.bucketName + tKeyName;
      //console.log("bucketName : ", bucketName);
      await axios.get(`${config.api.invokeURL}/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
              // Authorization: idToken,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
         //  console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
              enqueueSnackbar("Profile Photo Successfully Updated.",{variant:'success'}); 
            //  setNewProfileImage(newFileName);
              //  fetchGalleryImages();
                const tImageName=loggedUser.picture+".jpeg";  
                var timestamp = new Date().getTime();      
                setMyProfileImage(`${config.s3.ProfileImageURL}${"avatar/"}${loggedUser.picture}${"/"}${tImageName}${"?t="}${timestamp}`);
                setProfileImage(`${config.s3.ProfileImageURL}${"avatar/"}${loggedUser.picture}${"/"}${tImageName}${"?t="}${timestamp}`);

                //setMyProfileImage(imageFile);
                // setProfileImage(imageFile);
                 setImageFile('');
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
             }
        }).catch((error)=>{
           enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
       })
      }
    }catch(error){
     enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    }
  }


  return (
  <div>
     <Helmet>
      <title>Profile - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Profile - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Profile - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/profile" />
    </Helmet>
   
  <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Profile
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      My Profile
    </Breadcrumb.Item>
  </Breadcrumb>
  <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-lg-0">  
    <h4>My Profile</h4>
    </div>
    <div className="btn-toolbar mb-2 mb-lg-0"> 
        <ButtonGroup>
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle="profile"
          trigger={reactToPrintTrigger}
        />
        </ButtonGroup>
      </div>
    </div>
  <Preloader show={loaded ? false : true} />
      {/*<Header subtitle={subTitleMsg} />*/}
    <div ref={componentRef} >
    {profileImage && <ProfileImage formType={formType} profileImage={profileImage} setImageFile={setImageFile}/>}
    <Card style={{padding:"0px"}}>
      <Card.Body>
        <Form>
        <Row>
            <Col lg={12} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Degree Program</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                    value={profileDetails.courseCategory} disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, courseCategory:event.target.value})}}>
                  <option value="Bachealor of Theology">Bachealor of Theology</option>
                  <option value="Master of Theology">Master of Theology</option>
                  <option value="Licentiate in Sacred Theology">Licentiate in Sacred Theology</option>
                  <option value="Doctorate of Theology">Doctorate of Theology</option>
                </Form.Select>
              </Form.Group>
            </Col>
            {/*<Col lg={6} className="mb-3">
              <Form.Group id="applicantName">
                <Form.Label>Application Date</Form.Label>
                <Form.Control required type="date" value={profileDetails.applicationDate}
                                   disabled={viewOnly}
                                   onChange={(event)=>{setProfileDetails({...profileDetails, applicantName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>*/}
          </Row> 
          <Row>
            <Col lg={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Student Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your name...." value={profileDetails.applicantName}
                  disabled={viewOnly}
                  onChange={(event)=>{setProfileDetails({...profileDetails, applicantName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
            <Col lg={6} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Father Name</Form.Label>
                <Form.Control required type="text" value={profileDetails.fatherName}
                  placeholder='Enter Father name....' disabled={viewOnly}
                  onChange={(event)=>{setProfileDetails({...profileDetails, fatherName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" value={profileDetails.emailID}
                  disabled={viewOnly} placeholder='Enter your email address...'
                  onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col lg={6} className="mb-3">
            <Form.Label>Mobile Number</Form.Label>
            <Row>
              <Col lg={4} className="mb-3">
              <Form.Group id="areanumber">
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                type="select" name="courseName"
                                  value={profileDetails.areaCode} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, areaCode:event.target.value})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                              })}
                  </Form.Select>
                 </Form.Group>
            </Col>
             <Col lg={8} className="mb-3">
              <Form.Group id="mnumber">
                <Form.Control required type="number" placeholder="Enter your mobile number...." 
                                disabled={viewOnly}
                                value={profileDetails.mobileNumber} onChange={(event)=>{setProfileDetails({...profileDetails, mobileNumber:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            </Col>    
          </Row>
          <Row className="align-items-center">
            <Col lg={4} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control type="date" value={profileDetails.dateOfBirth}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, dateOfBirth:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Gender</Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.gender} 
                                    disabled={viewOnly}
                                    onChange={(event)=>{setProfileDetails({...profileDetails, gender:event.target.value})}}
                                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="nationality">
                <Form.Label>Nationality</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Nationality...." value={profileDetails.nationality}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, nationality:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mb-3">
              <Form.Group id="placeBirth">
                <Form.Label>Place of Birth</Form.Label> <br />
                <Form.Control type="text" size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.placeOfBirth} placeholder='Enter your place of birth....'
                                    disabled={viewOnly}
                                    onChange={(event)=>{setProfileDetails({...profileDetails, placeOfBirth:(event.target.value).toUpperCase()})}}
                                />
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="district">
                <Form.Label>District</Form.Label>
                <Form.Control required type="text" value={profileDetails.birthDistrict}
                  disabled={viewOnly} placeholder='Enter your district....'
                  onChange={(event)=>{setProfileDetails({...profileDetails, birthDistrict:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>

            <Col lg={4} className="mb-3">
              <Form.Group id="state">
                <Form.Label>State</Form.Label>
                <Form.Control required type="text" value={profileDetails.birthState}
                  disabled={viewOnly} placeholder='Enter your state....'
                  onChange={(event)=>{setProfileDetails({...profileDetails, birthState:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>Religious Status</Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.religiousStatus} 
                                 disabled={viewOnly}
                                 onChange={(event)=>{setProfileDetails({...profileDetails, religiousStatus:event.target.value})}}
                                >
                  <option value="Scholastic">Scholastic</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label>Parish & Diocese of Origin</Form.Label>
                <Form.Control required type="text" value={profileDetails.parishDiocese}
                  disabled={viewOnly} placeholder='Enter your parish & diocese details....'
                  onChange={(event)=>{setProfileDetails({...profileDetails, parishDiocese:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="diocese">
                <Form.Label>Congregation and Province</Form.Label>
                <Form.Control required type="text" value={profileDetails.congregationProvince}
                  disabled={viewOnly} placeholder='Enter your congregation & province details....'
                  onChange={(event)=>{setProfileDetails({...profileDetails, congregationProvince:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Card> Local Address
          <Row>
          <Col lg={12} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" value={profileDetails.localAddress}
                    disabled={viewOnly} placeholder='Enter your local address...'
                    onChange={(event)=>{setProfileDetails({...profileDetails, localAddress:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
              </Col>
          </Row>
          <Row>
          <Col lg={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control required type="text" placeholder="Enter your State" value={profileDetails.localState}
                    disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, localState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group id="localPincode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control required type="tel" placeholder="Postal Pincode" value={profileDetails.localPincode}
                    disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, localPincode:event.target.value})}}
                />
              </Form.Group>
            </Col>  
            <Col lg={4}>
              <Form.Group id="localContact">
                <Form.Label>Contact No</Form.Label>
                <Form.Control required type="tel" placeholder="phone number...." value={profileDetails.localContact}
                    disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, localContact:event.target.value})}}
                />
              </Form.Group>
            </Col>            
          </Row>
          </Card>
          <Card> Permanent Address
          <Row>
          <Col lg={12} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" value={profileDetails.perAddress}
                                  disabled={viewOnly} placeholder='Enter your permanent address...'
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perAddress:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
              </Col>
          </Row>
          <Row>
          <Col lg={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control required type="text" placeholder="Enter your State" value={profileDetails.perState}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group id="zip">
                <Form.Label>Pincode</Form.Label>
                <Form.Control required type="tel" placeholder="Postal Pincode" value={profileDetails.perPincode}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perPincode:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group id="zip">
                <Form.Label>Contact No</Form.Label>
                <Form.Control required type="tel" placeholder="Contact no...." value={profileDetails.perContactNo}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perContactNo:event.target.value})}}
                />
              </Form.Group>
            </Col>            
          </Row>
          </Card>
</Form>
</Card.Body>
    </Card>
    <Row className="p-4">
            <Col md={6}>
                    <DocPhotoProof setImageFile={setImageFile}/>
                </Col>
                <Col md={6}>
                <Button variant="primary" type="submit" onClick={(event)=>{postOnS3(event, "Profile")}}>Upload</Button>
                </Col>

    </Row>
    </div>
    </div>
  )
}
